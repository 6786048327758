import { HeroProps } from '@/src/sections/hero/v1';

export const heroData: HeroProps = {
  items: [
    {
      image: {
        src: 'assets/images/slider/slide-controle-de-ponto.webp',
        alt: 'hero 1',
      },
      title: 'Revenda Credenciada',
      description: 'A Akio Tech conta com consultores credenciados de sistemas de acessos lideres no mercado. Auxiliamos no crescimento e na gestão de acesso da sua empresa!',
      button: {
        label: 'Veja Sistemas de Controle de Acesso',
        href: '/produtos/controle-de-acesso',
      },
    },
    {
      image: {
        src: 'assets/images/slider/slide-idlock.webp',
        alt: 'hero 1',
      },
      title: 'Controle de Ponto e Acesso de Ponta',
      description: 'Oferecemos uma gama abrangente e flexível de serviços. Somos especializados em diversos meios de controle de ponto!',
      button: {
        label: 'Veja Relógios de Ponto',
        href: '/produtos/controle-de-ponto',
      },
    },
    {
      image: {
        src: 'assets/images/slider/consultores-especializados.jpg',
        alt: 'hero 1',
      },
      title: 'Consultores Especializados',
      description: 'A Akio Tech conta com consultores credenciados de sistemas de acessos lideres no mercado. Auxiliamos no crescimento e na gestão de acesso da sua empresa!',
      button: {
        label: 'Faça uma cotação',
        href: '/cotacao',
      },
    },
  ],
};
