'use client';

import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'framer-motion';

interface CounterProps {
  end: number;
  suffix?: string;
  duration?: number;
  delay?: number;
}

export const Counter: FunctionComponent<CounterProps> = ({
  end,
  suffix,
  delay,
  duration,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(ref, { once: true });
  const [viewPortEntered, setViewPortEntered] = useState(false);

  useEffect(() => {
    if (isInView) {
      setViewPortEntered(true);
    }
  }, [isInView]);

  return (
    <div ref={ref}>
      <CountUp
        suffix={suffix}
        end={end}
        delay={delay}
        duration={duration}
        start={viewPortEntered ? undefined : 0}
      >
        {({ countUpRef }) => (
          <span ref={countUpRef} />
        )}
      </CountUp>
    </div>
  );
};
