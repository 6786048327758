import { HeaderProps } from '@/src/layout/header/desktop/v2';
import { menuItemsProps } from '../menu';

export const headerData: HeaderProps = {
  contactInfo: {
    phone: '(11) 9 6613-4802',
    mail: 'comercial@akiotech.com.br',
    address: 'Jundiaí - SP',
  },
  ctaBtn: {
    href: '/',
    label: 'Fazer uma cotação',
    openNewTab: false,
  },
  menuItems: menuItemsProps,
};
