import { HeaderProps } from '@/src/layout/header/desktop/v1';

export const menuItemsProps: HeaderProps['menuItems'] = [
  {
    label: 'Home',
    href: '/',
  },
  {
    title: 'Produtos',
    subMenuItems: [
      {
        label: 'Controle de Ponto',
        href: '/produtos/controle-de-ponto',
      },
      {
        label: 'Controle de Acesso',
        href: '/produtos/controle-de-acesso',
      },
      {
        label: 'Fechadura Eletrônica',
        href: '/produtos/fechadura-eletronica',
      },
      {
        label: 'Automação Comercial',
        href: '/produtos/automacao-comercial',
      },
    ],
  },
  {
    label: 'Cotação',
    href: '/cotacao',
  },  
  {
    label: 'Contato',
    href: '/contato',
  },  
  {
    label: 'Suporte',
    href: '/suporte',
  },
  {
    label: 'Sobre nós',
    href: '/sobre-nos',
  },
];
