import { HeaderProps } from '@/src/layout/header/desktop/v1';
import { menuItemsProps } from '../menu';

export const headerData: HeaderProps = {
  menuItems: menuItemsProps,
  contactInfo: {
    title: 'Vamos conversar?',
    phone: '(11) 9 6613-4802',
  },
};
